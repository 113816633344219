<template>
  <div class="btn-floating">
    <button class="button is-primary body2-bold" :style="buttonStyle" @click="$emit('clicked')">{{ text }}</button>
  </div>
</template>
<script>
  export default {
    name: "BtnFloating",
    props: {
      text: {
        type: String,
        required: true
      },
      height: {
        type: Number
      }
    },
    created() {
    },
    data() {
      return {}
    },
    computed: {
      buttonStyle() {
        let deco = {};
        if(this.height) {
          deco.height = `${this.height}px`;
        }
        return deco;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  .btn-floating
    position fixed
    border-top 1px solid #e3e3e3
    left 0
    bottom 0
    width 100%
    z-index 100
    padding 12px
    background-color white

  .btn-floating > .button
    width 100%
</style>
